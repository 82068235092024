import {
  SET_ACTIVE_TAB,
  SET_DOWNLOADING,
  SET_LICENSE_HISTORY,
  SET_FETCHING_LICENSE_HISTORY,
  SET_ACTIVE_FILTER,
  SET_PURCHASE_HISTORY,
  SET_PURCHASE_HISTORY_FILTER,
  SET_FETCHING_PURCHASE_HISTORY,
  SET_GIFT_CARDS
} from "../actions/history/index";

let initialState = {
  activeTab: "licenses",
  downloading: false,
  licenses: {
    data: [],
    fetching: true,
    filter: "all"
  },
  purchases: {
    data: [],
    fetching: true,
    filter: "all"
  },
  giftCards: []
};

export default function user(state = initialState, action) {
  const setState = (obj) => Object.assign({}, state, obj);

  switch (action.type) {
    case SET_ACTIVE_TAB:
      return setState({ activeTab: action.payload });
    case SET_DOWNLOADING:
      return setState({ downloading: action.payload });

    case SET_LICENSE_HISTORY:
      return setState({ licenses: Object.assign({}, state.licenses, { data: action.payload }) });
    case SET_ACTIVE_FILTER:
      return setState({ licenses: Object.assign({}, state.licenses, { filter: action.payload }) });
    case SET_FETCHING_LICENSE_HISTORY:
      return setState({
        licenses: Object.assign({}, state.licenses, { fetching: action.payload })
      });
    case SET_FETCHING_PURCHASE_HISTORY:
      return setState({
        purchases: Object.assign({}, state.purchases, { fetching: action.payload })
      });
    case SET_PURCHASE_HISTORY:
      return setState({ purchases: Object.assign({}, state.purchases, { data: action.payload }) });
    case SET_PURCHASE_HISTORY_FILTER:
      return setState({
        purchases: Object.assign({}, state.purchases, { filter: action.payload })
      });
    case SET_GIFT_CARDS:
      return setState({ giftCards: action.payload });
    default:
      return state;
  }
}
