export const SET_ACTIVE_TAB = "HISTORY/SET_ACTIVE_TAB";
export const SET_DOWNLOADING = "HISTORY/SET_DOWNLOADING";
export const SET_LICENSE_HISTORY = "HISTORY/SET_LICENSE_HISTORY";
export const SET_FETCHING_LICENSE_HISTORY = "HISTORY/SET_FETCHING_LICENSE_HISTORY";
export const SET_ACTIVE_FILTER = "HISTORY/SET_ACTIVE_FILTER";

export const SET_PURCHASE_HISTORY = "HISTORY/SET_PURCHASE_HISTORY";
export const SET_PURCHASE_HISTORY_FILTER = "HISTORY/SET_PURCHASE_HISTORY_FILTER";
export const SET_FETCHING_PURCHASE_HISTORY = "HISTORY/SET_FETCHING_PURCHASE_HISTORY";

export const SET_GIFT_CARDS = "HISTORY/SET_GIFT_CARDS";
