export const getShopifyProductsQuery = `
query {
  products(first: 100) {
    edges {
      node {
        id
        availableForSale
        createdAt
        updatedAt
        descriptionHtml
        description
        handle
        productType
        title
        vendor
        publishedAt
        onlineStoreUrl
        options {
          name
          values
        }
        images(first: 250) {
          edges {
            node {
              id
              src: url
              altText
              width
              height
            }
          }
        }
        variants(first: 250) {
          edges {
            node {
              id
              title
              price {
                amount
                currencyCode
              }
              priceV2: price {
                amount
                currencyCode
              }
              weight
              available: availableForSale
              sku
              compareAtPrice {
                amount
                currencyCode
              }
              compareAtPriceV2: compareAtPrice {
                amount
                currencyCode
              }
              image {
                id
                src: url
                altText
                width
                height
              }
              selectedOptions {
                name
                value
              }
              unitPrice {
                amount
                currencyCode
              }
              unitPriceMeasurement {
                measuredType
                quantityUnit
                quantityValue
                referenceUnit
                referenceValue
              }
            }
          }
        }
      }
    }
  }
}
`;

export const createShopifyCartQuery = `mutation createCart($cartInput: CartInput) {
  cartCreate(input: $cartInput) {
    cart {
      id
      checkoutUrl
      attributes {
        key
        value
      }
      lines(first: 100) {
        edges {
          node {
            id
            cost {
              totalAmount {
                amount
              }
            }
            merchandise {
              ... on ProductVariant {
                id
                title
              }
            }
            attributes{
              key
              value
            }
          }
        }
      }
      cost {
        totalAmount {
          amount
          currencyCode
        }
      }
      buyerIdentity {
        email
        phone
        customer {
          id
        }
        countryCode
        deliveryAddressPreferences {
          ... on MailingAddress {
            address1
            address2
            city
            provinceCode
            countryCodeV2
            zip
          }
        }
        preferences {
          delivery {
            deliveryMethod
          }
        }
      }
    }
  }
}`;

export const fetchShopifyCartQuery = `query($id: ID!) {
  cart(id: $id) {
    id
    createdAt
    updatedAt
    note
    checkoutUrl
    totalQuantity
    lines(first: 100) {
      edges {
        node {
          id
          quantity
          cost {
            totalAmount {
              amount
            }
          }
          merchandise {
            ... on ProductVariant {
              id
            }
          }
          attributes {
            key
            value
          }
        }
      }
    }
    attributes {
      key
      value
    }
    cost {
      totalAmount {
        amount
        currencyCode
      }
      subtotalAmount {
        amount
        currencyCode
      }
      totalTaxAmount {
        amount
        currencyCode
      }
      totalDutyAmount {
        amount
        currencyCode
      }
    }
    buyerIdentity {
      email
      phone
      customer {
        id
      }
      countryCode
      deliveryAddressPreferences {
        ... on MailingAddress {
          address1
          address2
          city
          provinceCode
          countryCodeV2
          zip
        }
      }
      preferences {
        delivery {
          deliveryMethod
        }
      }
    }
  }
}
`;
